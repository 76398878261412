<template>
	<div id="sparseDenseSetting">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="tempStatus" label="体温状况:">
				<el-select v-model="form.tempStatus" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="time" label="查询时间:" :span="1.5">
				<el-date-picker
					v-model="form.time"
					:unlink-panels="true"
					value-format="yyyy-MM-dd"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				>
				</el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('list') || true">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('reset') || true">重置</el-button>
				<!-- <el-row> -->
				<!-- <el-button type="primary" v-on:click="addUdate()">新增</el-button>
                            <el-button type="primary" @click="batchdelete">批量删除</el-button> -->
				<!-- </el-row> -->
			</template>
		</jy-query>
		<jy-table max-height="595" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="name" label="姓名" min-width="100"></jy-table-column>
			<jy-table-column prop="mobile" label="联系方式" min-width="120"></jy-table-column>
			<jy-table-column prop="routeName" label="线路" min-width="100"></jy-table-column>
			<jy-table-column prop="siteName" label="站点" min-width="120"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌" min-width="120"></jy-table-column>
			<jy-table-column prop="time" label="时间" min-width="160"></jy-table-column>
			<jy-table-column prop="temperature" label="体温（℃）" min-width="100"></jy-table-column>
			<jy-table-column prop="tempStatus" label="体温状态" min-width="100"></jy-table-column>
			<jy-table-column label="照片抓拍" min-width="120">
				<template slot-scope="scope">
					<el-image :src="scope.row.picUrl" class="logo_img" fit="cover" :preview-src-list="[scope.row.picUrl]">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					</el-image>
				</template>
			</jy-table-column>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
	</div>
</template>
<script>
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			form: {
				tempStatus: "",
				time: ""
			},
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			options: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "0",
					label: "正常"
				},
				{
					value: "1",
					label: "低烧"
				},
				{
					value: "2",
					label: "高烧"
				}
			],

			btnRoute: "/admin/passengerTemperature/show"
		};
	},
	filters: {},
	watch: {},
	mixins: [btnMixins],
	components: {},

	computed: {},

	activated() {},

	created() {
		this.getList();
	},

	methods: {
		getList() {
			if (this.form.time) {
				this.form.startTime = this.form.time[0];
				this.form.endTime = this.form.time[1];
			}
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/temperature/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				this.dataList = detail.list;
				this.total = detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped="show">
.logo_img {
	width: 60px;
	height: 60px;

	i {
		font-size: 60px;
	}
}
</style>
